<template>
  <div class="">
    <el-dialog
      :visible.sync="dialogFormVisible"
      :before-close="handleClose"
      :width="$isMobile ? '350px' : '500px'"
    >
      <div v-html="info.content"></div>
      <!-- <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="confirm"> Nạp thẻ </el-button>
      </div> -->
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "",
  mixins: [],
  components: {},
  props: {},
  data() {
    return {
      dialogFormVisible: false,
      info: {},
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    confirm() {
      this.dialogFormVisible = false;
    },

    handleClose() {
      this.dialogFormVisible = false;
    },
  },
};
</script>

<style scoped lang="scss">
/deep/.el-dialog {
  border-radius: 32px !important;
}

@media screen and (max-width: 1000px) {
  /deep/.el-dialog {
    width: 90%;
  }
}
</style>
